.navbar {
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: 0.5s;
}

.navbar__light {
  background-color: var(--white);
}

.navbar__content {
  /* width: 100%; */
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.navbar__content .logo {
  width: 12rem;
}

.nav__links {
  display: flex;
  gap: 2rem;
  list-style: none;
  align-items: center;
}

.navbar__light .link {
  color: var(--dark-purple);
}

.link {
  text-decoration: none;
  color: var(--white);
  font-size: 1.5rem;
  font-weight: 700;
  transition: 0.5s;
}

.link:hover {
  color: var(--color2--);
}

.navbar__light .menu {
  color: var(--dark-purple);
}

.menu {
  display: none;
  color: var(--white);
  cursor: pointer;
  font-size: 4rem;
}

@media screen and (max-width: 768px) {
  .nav__links {
    display: none;
  }

  .menu {
    display: block;
  }
}
