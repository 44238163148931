.bx-menu {
  font-size: 38px;
  color: var(--dark-purple);
  padding: 0.2em;
}
.menubar .closeIcon {
  display: none;
}
.menubar.active .closeIcon {
  display: block;  
}

.menubar {
  position: fixed;
  top: 0;
  overflow: hidden;
  width: 0%;
  z-index: 10000;
  right: 0;
  background-color: var(--white);
  height: 100vh;
  transition: 0.5s;
  opacity: 0;
}

.menubar.active {
  width: 100%;
  opacity: 1;
}

.menubarHeader {
  display: flex;
  padding: 2rem;
  flex-direction: row-reverse;
}

.menubarHeader i {
  font-size: 4rem;
  cursor: pointer;
  color: var(--dark-purple);
}

.menubarLinks {
  width: 100%;
  list-style: none;
  padding: 0;
}

.menubarLink {
  display: flex;
  justify-content: center;
  padding: 1em 0;
  font-size: 2em;
}

.menubarLink .link {
  color: var(--dark-purple);
  text-decoration: none;
  font-weight: 800;
  border-bottom: 6px solid var(--yellow);
}
