.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #3d0b9a76;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.modal-body {
  max-width: 50%;
  border-radius: 1rem;
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  animation: show 0.3s;
  transition: 0.5s;
}

@keyframes show {
  from {
    opacity: 0;
    transform: scale(0);
  }
}

@media only screen and (max-width: 600px) {
  .modal-body {
    width: 95%;
  }
}
