.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-wrap: nowrap;
    border-radius: 1rem;
}

.button--primary {
    background-color: var(--yellow);
    color: var(--dark-purple);
}

.button--primary:hover {
    opacity: 0.8;
    transform: translateY(-0.4rem);
}

.button--primary:active {
    transform: translateY(0.2rem);
}

.button--secondary {
    background-color:transparent;
    color: var(--yellow);
    border: 1px solid var(--yellow);
}

.button--secondary:hover {
    transform: translateY(-0.4rem);
    background-color: var(--yellow);
    color: var(--dark-purple);
}

.button--secondary:active {
    transform: translateY(0.2rem);
}

.button--small {
    padding: 0.5rem 1rem;
    font-size: 1rem;
}

.button--medium {
    padding: 1rem 2rem;
    font-size: 1.2rem;
}

.button--large {
    padding: 1.5rem 3rem;
    font-size: 1.5rem;
}

.button__fullWidth {
    width: 100%;
}